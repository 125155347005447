import React, { Component } from 'react';
import DropDownIcon from '../resources/images/dropdown.png';
import './dropdown.css';

var dropDownStyle = {
  backgroundImage: `url(${DropDownIcon})`
}

function isFunction(functionToCheck) {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

class DropDown extends Component {
  constructor(props)
  {
    super(props);
    let defaultValue = "";
    if (this.props.value) {
      defaultValue = this.props.value;
    }
    this.state = {
      value: defaultValue
    }
    this.optionList = isFunction(this.props.options) ? this.props.options() : this.props.options;

    this.handleChange = this.handleChange.bind(this);
  }
  
  buildOptions() {
    let options = Object.keys(this.optionList).map((key, index) => 
      <option key={index} value={key}>{this.optionList[key]}</option>
    );
    return options;
  }
  
  buildLabel() {
    if (this.props.label) {
      return (
        <div className="label">
          <label>
            {this.props.label}
          </label>
        </div>
      )
    }
    return null;
  }
  
  handleChange(event) {
    this.setState({value: event.target.value});

    this.props.onChange(event);
    event.preventDefault();
  }
    
  render() {
    return (
      <div className="DropDown">
        {this.buildLabel()}
        <select
              value={this.state.value}
              onChange={this.handleChange}
              style={{...dropDownStyle, ...this.props.style }}>
          {this.buildOptions()}
        </select>
      </div>
    );
  }
}

export default DropDown;
