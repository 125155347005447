import React, { Component } from 'react';
import Styled from 'styled-components';

import {UserContext} from '../../Login/UserContext';
import ParamParseLib from '../../common/react-component-library/lib/ParamParse/paramParse.js';
import FormCheckbox from '../../common/react-component-library/FormCheckbox';
import FormInput from '../../common/react-component-library/FormInput';
import RadioButton from '../../common/react-component-library/RadioButton';
//import ConvFeesFields from './ConvFeesFields';
import Icon from '../../common/react-component-library/Icon';

import './SettingsPrefDesigner.css';

import {GetPaymentLink} from '../../../lib/comms';
import DropDown from '../../common/react-component-library/DropDown/index.js';


// Terms Link   (component parameters) 
//    a url given by the merchant that has the merchant's terms of service that can be viewed by the cardholder
//  
//  keys:
//    link:     the terms link url
const ParameterTermsLink =
{
  name: "termsLink",
  keys: {link: "link"},
}


// Transaction Type   (component parameters)
//   indicates which transactions the merchant is allowing the cardholder to choose from
//    Type of transactions available should be added as 'true' if the cardholder has access to them
//    otherwise, set to false or, preferably, the key/value pair for the transaction type should be removed from the component 
//
//  keys:
//      type:       indicate the type of transaction. can be: "sale", "auth", or "strFwd"
const ParameterTransactionType =
{
  name: "tranxType",
  keys: {type: "type"},
  type: {sale: "sale", auth: "auth", strFwd: "strFwd"},
}

const ParameterSECCode =
{
  name: "secCode",
  keys: {type: "type"},
  type: {CCD: "CCD", WEB: "WEB"},
}


// Customer Login
//    no key/value pairs necessary
//    Enable/disable by making the setting the component visible/invisible, or removing the component from the parameter library
const ParameterCustomerLogin =
{
  name: "customerLogin",
}

//Convenience Fee   (component parameters)
// Keys:
//    type:         indicates the type of convenience fee. Can be either "fixed" or "percentage" 
//    amount:       the amount of the convenience fee. 
//    title:        (optional) 50 characters max. 
const ParameterConvenienceFee =
{
  name: "convFee",
  keys: {type: "type", amount: "amount", title: "title"},
  type: {fixed: "fixed", percentage: "percentage"}
}

// Surcharge Fee
//  no key/value pairs necessary
//  Enable/disable by making the component visible/invisible, or removing component from the parameter library
const ParameterSurchargeFee = 
{
  name: "surFee",
}

// Custom Title
//    Setting the custom title sets the title used by the merchant on Comus in the company info section
// Keys:
//    customTitle:        (optional) 50 characters max. 
const ParameterCustomTitle =
{
  name: "customTitle",
  keys: {customTitle: "customTitle"}
}


// Sale Ceiling Amount
//    Setting the Sale ceiling amount sets the ceiling amount limit by the merchant to be used on Comus to check the allowed sale amount.
// Keys:
//    amount:   Default value
const ParameterSaleCeilingAmount =
{
  name: "saleCeilingAmount",
  keys: { amount: "amount"}
}


// invoice Field
//    no key/value pairs necessary
//    Show/Hide by making the setting the component visible/invisible, or removing the component from the parameter library
//    Required/Not Required by making the setting the component required, or by removing the component from the parameter library

const ParameterInvoice =
{
  name: "invoice"  
}

const PRIMARY_COLOR = '#4696CA';
const SECONDARY_COLOR = '#B2B7C3';

const TransactionType={
  "SALE": "sale",
  "AUTH": "auth",
  "STRFWD": "strFwd"
};

const CustomerLogin={
  "ENABLE": "Y",
  "DISABLE": "N"
};

const DisplayInvoice={
  "SHOW": "Show",
  "HIDE": "Hide",
  "SHOWANDREQUIRE": "Show and Require"
};

const FeeType={
  "SURCHARGE":"surcharge",
  "CONVENIENCE":"convenience"
}

const ConvFeeType={
  "FIXED": "fixed",
  "PERCENTAGE": "percentage"
};

const LabelFeeType={
  "CONVENIENCE": "Convenience",
  "SURCHARGE": "Surcharge"
}

const LabelConvFeeType={
  "FIXED": "Fixed",
  "PERCENTAGE": "Percentage"
};

const VALID_SEC_CODES = ["CCD", "WEB"]

const DEFAULT_SALE_CEILING_AMOUNT = 99999999.99;

class SettingsPrefDesigner extends Component {
  constructor(props){
    super(props);
    const PRIMARY = '#4696CA';
    const SECONDARY = '#B2B7C3';
    
    this.state = {
      hasTraceACH: false,
      available_std_entry_classes: [],
      transTypeSale:true,
      transTypeAuth:false,
      transTypeStrFwd:false,
      selectedTransType:TransactionType.SALE,
      fee:false,
      convFee:false,
      surFee:false,
      surConvFeeChange: "",
      termsLink:"http://",
      selectedCustomerLogin:CustomerLogin.ENABLE,
      SelectedDisplayInvoice:DisplayInvoice.SHOW,
      SelectedSecCode: "",
      selectedConvFeeType:ConvFeeType.FIXED,
      selectedFeeType:null,
      convenienceFeeAmount:"",
      convenienceFeeTitle:"Fee",
      isConvFeeAmountValid:true,
      customTitle: "Payment",                       //by default, will display "Payment" as the custom title 
      saleCeilingAmount: Number(DEFAULT_SALE_CEILING_AMOUNT).toLocaleString('en-US'),   // Default value
      displayConvFeeUI: true,                       //by default we will display the convenience fee field for the merchant
      displayCustomerLoginUI: true,                 //by default we will display the 'enable customer login' ui field for the merchant
      errorLabelText:"",
      errorLableClassName:""
    }
    
    this.handleChangeTransactionType = this.handleChangeTransactionType.bind(this);
  
    this.handleTermsLinkChange = this.handleTermsLinkChange.bind(this);
    this.handleCheckFee = this.handleCheckFee.bind(this);
    this.handleCheckConvFee = this.handleCheckConvFee.bind(this);

    this.handleCustomerLoginEnableChange = this.handleCustomerLoginEnableChange.bind(this);
    this.handleCustomerLoginDisableChange = this.handleCustomerLoginDisableChange.bind(this);
    
    this.handleDisplayInvoiceChange = this.handleDisplayInvoiceChange.bind(this);
    
    this.handleFeeChange = this.handleFeeChange.bind(this);
    this.handleConvFeeTypeFixedChange = this.handleConvFeeTypeFixedChange.bind(this);
    this.handleConvFeeTypePercentageChange = this.handleConvFeeTypePercentageChange.bind(this);
    
    this.handleConvFeeAmountChange = this.handleConvFeeAmountChange.bind(this);

    this.handleConvFeeFieldTitleChange = this.handleConvFeeFieldTitleChange.bind(this);
    this.handleCustomTitleChange = this.handleCustomTitleChange.bind(this);

    this.handleSecCodeChange = this.handleSecCodeChange.bind(this)
    this.handleSaleCeilingAmountChange = this.handleSaleCeilingAmountChange.bind(this);
    
    this.onSaveClick = this.onSaveClick.bind(this);
    this.saveParameters = this.saveParameters.bind(this);
  }


  handleChangeTransactionType(evt){  
    ParamParseLib.addComponentKeyAndValue(ParameterTransactionType.name, ParameterTransactionType.keys.type, evt.target.value);
    this.setState( {selectedTransType: evt.target.value} );
  }
  
  handleSecCodeChange(event) 
  {
    var selectedValue = event.target.value;
    ParamParseLib.addComponentKeyAndValue(ParameterSECCode.name, ParameterSECCode.keys.type, event.target.value);
    this.setState( { SelectedSecCode: selectedValue, errorLabelText: "", errorLableClassName: ""} );
  }
  
  
  
  handleCustomerLoginEnableChange(evt)
  {
    ParamParseLib.setComponentVisible(ParameterCustomerLogin.name, true)

    this.setState( {selectedCustomerLogin: CustomerLogin.ENABLE} );
  }
  
  
  handleCustomerLoginDisableChange(evt)
  {
    ParamParseLib.removeComponent(ParameterCustomerLogin.name)

    this.setState( {selectedCustomerLogin: CustomerLogin.DISABLE} );
  }

  handleDisplayInvoiceChange(evt){

    var selectedValue = evt.target.value;
    
    this.setState({SelectedDisplayInvoice: selectedValue})
  
    if(selectedValue == DisplayInvoice.SHOW){
      ParamParseLib.setComponentVisible(ParameterInvoice.name, true)
      ParamParseLib.setComponentRequired(ParameterInvoice.name, false);
      return
    }
    
    if(selectedValue == DisplayInvoice.SHOWANDREQUIRE) {
      ParamParseLib.setComponentVisible(ParameterInvoice.name, true)
      ParamParseLib.setComponentRequired(ParameterInvoice.name, true);
      return
    }

    if (selectedValue == DisplayInvoice.HIDE){
      ParamParseLib.setComponentVisible(ParameterInvoice.name, false)
      ParamParseLib.setComponentRequired(ParameterInvoice.name, false); 
    }  
  }
  

  handleCheckFee(evt)
  {
    if(this.state.fee){
      //reset
      ParamParseLib.setComponentVisible(ParameterConvenienceFee.name, false);
      ParamParseLib.setComponentVisible(ParameterSurchargeFee.name, false);
      this.setState({errorLabelText: "", errorLableClassName: "", convFee: false, surFee: false, selectedFeeType: null})
    }

    this.setState({fee: !this.state.fee});
  }


  handleCheckConvFee(evt)
  {
    ParamParseLib.setComponentVisible(ParameterConvenienceFee.name,  !this.state.convFee)
    ParamParseLib.removeComponent(ParameterSurchargeFee.name)

    if(this.state.convFee){
      //reset 
      this.setState({errorLabelText: "", errorLableClassName: "", isValidConvFeeAmount:true, convenienceFeeAmount:null })
    }
    
    this.setState( {convFee: !this.state.convFee, surFee: false} );
    
  }

  handleCheckSurFee(evt)
  {
    ParamParseLib.setComponentVisible(ParameterSurchargeFee.name, !this.state.surFee)
    ParamParseLib.removeComponent(ParameterConvenienceFee.name)

    if(this.state.surFee){
      this.setState({errorLabelText: "", errorLableClassName: ""})
    }

    this.setState({surFee: !this.state.surFee, convFee: false} );
  }


  handleFeeChange(evt)
  {
    var selectedValue = evt.target.value;

    if(this.state.selectedFeeType === FeeType.CONVENIENCE && selectedValue === FeeType.SURCHARGE) {
      this.setState({ surConvFeeChange: " Enabling surcharge fees for payment link will disable convenience fee." });
    } else {
      this.setState({ surConvFeeChange: "" });
    }

    this.setState({selectedFeeType: selectedValue})

    if(selectedValue == FeeType.SURCHARGE){
      this.handleCheckSurFee(evt);
      return
    }

    if(selectedValue == FeeType.CONVENIENCE){
      this.handleCheckConvFee(evt);
    }
  }

  
  handleTermsLinkChange(evt){
    ParamParseLib.addComponentKeyAndValue(ParameterTermsLink.name, ParameterTermsLink.keys.link, evt.value() )

    this.setState( {termsLink: evt.value() } ); 
  }
  
  
  handleConvFeeTypeFixedChange(evt)
  {
    ParamParseLib.addComponentKeyAndValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.type, ParameterConvenienceFee.type.fixed )

    this.setState( {selectedConvFeeType: ConvFeeType.FIXED, errorLabelText: "", errorLableClassName: ""} );
  }
  
  
  handleConvFeeTypePercentageChange(evt){
    ParamParseLib.addComponentKeyAndValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.type, ParameterConvenienceFee.type.percentage )

    this.setState( {selectedConvFeeType: ConvFeeType.PERCENTAGE, errorLabelText: "", errorLableClassName: ""} );
  }
  
  
  handleConvFeeAmountChange(evt)
  {
    var value = evt.value();
    let validConvAmount = true;
    let tempErrorLabelText = "Please provide a valid Fee Amount";
    let tempErrorClassName = "alert alert-danger";
    
    if(this.isValidConvFeeAmount(value))
    {  
      ParamParseLib.addComponentKeyAndValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.amount, value ); 
      tempErrorLabelText = "";
      tempErrorClassName = "";
    }
    else {
      validConvAmount = false;
    }
    this.setState( {convenienceFeeAmount: value.trim() , isConvFeeAmountValid: validConvAmount , 
                    errorLabelText: tempErrorLabelText, errorLableClassName: tempErrorClassName} );
  }
  
    
  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }


  getSelectedFeeType(convFee, surFee){
    let selectedFeeType = null;

    if(convFee){
      selectedFeeType = FeeType.CONVENIENCE;
    } else if (surFee) {
      selectedFeeType = FeeType.SURCHARGE;
    }

    return selectedFeeType;
  }
  
  
  handleConvFeeFieldTitleChange(evt){
    ParamParseLib.addComponentKeyAndValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.title, evt.value() )

    this.setState( {convenienceFeeTitle: evt.value()} );
  }
  
  
  handleCustomTitleChange(evt){
    ParamParseLib.addComponentKeyAndValue(ParameterCustomTitle.name, ParameterCustomTitle.keys.customTitle, evt.value() )

    this.setState( {customTitle: evt.value()} );
  }
  
  
  handleSaleCeilingAmountChange(evt){
    
    //remove if any currency formating with comma before storing
    var scAmount = this.formatSaleCeilingAmount(evt.value());
    
    ParamParseLib.addComponentKeyAndValue(ParameterSaleCeilingAmount.name, ParameterSaleCeilingAmount.keys.amount, scAmount );
    this.setState( { saleCeilingAmount: evt.value(), errorLabelText: "", errorLableClassName: ""} );
  }
  
  
  // This function is used for formating Sale Ceiling amount with comma and 2 decimal places.
  currencyFormat(num) {
    return Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')   //returns e.g 2,345.00
  }
  
  
  convertTransactionTypeHack(val) {
    if (val == null) {
      throw "transaction type for a merchant's cart is undefined"
    }
    if (val.toUpperCase().trim() == 'A') {
      return 'auth';
    }
    if (val.toUpperCase().trim() == 'S') {
      return 'sale';
    }
    return val;
  }

  isTraceACHEnabled(merchantInfo){
    const hasACH = merchantInfo?.ach === true
    const {ach_processor_code, ach_processor_status} = merchantInfo

    if (hasACH && ach_processor_code === "VCI" && !!ach_processor_status){
      return true
    }
    return false
  }

  getMerchantSecCodes(merchantInfo){
    return VALID_SEC_CODES.filter(element => merchantInfo.ach_std_entry_classes.includes(element))
  }

  
  setupSettings(merchantInfo)
  {
    this.setState( {convenienceFeeAmount: ParamParseLib.getComponentKeyValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.amount)}); 
 
    let tranxType = this.convertTransactionTypeHack(ParamParseLib.getComponentKeyValue(ParameterTransactionType.name, ParameterTransactionType.keys.type));
    this.setState( {selectedTransType: tranxType});

    let isVis = (ParamParseLib.getComponentVisible(ParameterCustomerLogin.name) && !merchantInfo.isBasic)? CustomerLogin.ENABLE : CustomerLogin.DISABLE;
    if (ParamParseLib.getComponentVisible(ParameterCustomerLogin.name) && merchantInfo.isBasic) {
      ParamParseLib.removeComponent(ParameterCustomerLogin.name);
    }
    this.setState({selectedCustomerLogin: isVis});
  
    if (ParamParseLib.getDecodingVersion() != 1){
      let isVisInvoice = ParamParseLib.getComponentRequired(ParameterInvoice.name) ? DisplayInvoice.SHOWANDREQUIRE : 
                         ParamParseLib.getComponentVisible(ParameterInvoice.name) ? DisplayInvoice.SHOW : DisplayInvoice.HIDE ;    
      this.setState( {SelectedDisplayInvoice: isVisInvoice} );
    }

    let hasConvFee = ParamParseLib.getComponentVisible(ParameterConvenienceFee.name);
    let hasSurFee = ParamParseLib.getComponentVisible(ParameterSurchargeFee.name);
    let hasFee = hasConvFee || hasSurFee;


    let hasTraceACH = this.isTraceACHEnabled(merchantInfo)
    if (hasTraceACH){
      this.setState({
        available_std_entry_classes: this.getMerchantSecCodes(merchantInfo),
        SelectedSecCode: ParamParseLib.getComponentKeyValue(ParameterSECCode.name, ParameterSECCode.keys.type),
      })
    }


    this.setState( {
      fee: hasFee,
      surFee: hasSurFee,
      convFee: hasConvFee,
      hasTraceACH: hasTraceACH,
    });

    this.setState( {termsLink: ParamParseLib.getComponentKeyValue(ParameterTermsLink.name, ParameterTermsLink.keys.link) } ); 

    this.setState( {selectedFeeType: this.getSelectedFeeType(hasConvFee, hasSurFee) } );

    this.setState( {selectedConvFeeType: ParamParseLib.getComponentKeyValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.type)} );

    this.setState( {convenienceFeeTitle: ParamParseLib.getComponentKeyValue(ParameterConvenienceFee.name, ParameterConvenienceFee.keys.title)} );

    this.setState( {customTitle: ParamParseLib.getComponentKeyValue(ParameterCustomTitle.name, ParameterCustomTitle.keys.customTitle)} );

    //Note: Sale celing amount was added with version 3
    if( ParamParseLib.getDecodingVersion() >= 3 ){
      //formated Sale Ceiling Amount for initial display
      let storedCeilingAmount = ParamParseLib.getComponentKeyValue(ParameterSaleCeilingAmount.name, ParameterSaleCeilingAmount.keys.amount);
      storedCeilingAmount = isNaN(Number(storedCeilingAmount)) ? "99999999.99" : storedCeilingAmount       

      let formatedSaleCeilingAmount = this.currencyFormat(storedCeilingAmount);
      this.setState({ saleCeilingAmount: formatedSaleCeilingAmount })
    }

  }


  getLinkParams(merchantInfo)
  {

    if (this.state.isDoneLoading != true)
    {
      GetPaymentLink().then(function(paymentLinkParams)
      {
        var encodedParamString = ""
        try 
        {
          encodedParamString = window.atob(paymentLinkParams.LinkParams); // try to convert the base64 string
        } 
        catch(e) 
        { // invalid base64 so try to just deconstructEncodedParameterString
          encodedParamString = paymentLinkParams;
        }
        ParamParseLib.deconstructEncodedParameterString(encodedParamString);

      }.bind(this)).finally(function()
      {
        this.setState(
        {
          isDoneLoading: true,
        });
        this.setupSettings(merchantInfo);
      }.bind(this));
    }
  }


  onSaveClick()
  {
    var errorMsg = "" ;
    var tempErrorClassName = "alert alert-danger";
    
    if(!this.isValidFeeType()){
      errorMsg = "Please select a Fee Type.";
      this.setState({errorLabelText: errorMsg, errorLableClassName:tempErrorClassName })
      return;
    }

    if(!this.isValidConvFeeType()){
      errorMsg = "Please select a Convenience Fee Type.";
      this.setState({errorLabelText: errorMsg, errorLableClassName: tempErrorClassName })
      return;
    }
    
    if(!this.isValidConvFeeAmount(this.state.convenienceFeeAmount)){
      errorMsg = "Please provide a valid Fee amount.";
      this.setState({errorLabelText: errorMsg, errorLableClassName: tempErrorClassName, isConvFeeAmountValid:false })
      return;
    }
    
    if(!this.isValidSaleCeilingAmount(this.state.saleCeilingAmount)){
      errorMsg = "Please Provide a valid Sale Ceiling amount.";
      this.setState({errorLabelText: errorMsg, errorLableClassName: tempErrorClassName })
      return;
    }

    // Enforcing validation when processor is VCI and merchant has allowed sec codes
    if(this.state.hasTraceACH && this.state.available_std_entry_classes.length > 0){
      if(!this.isValidSECcode()){
        errorMsg = "Please select a SEC Code";
        this.setState({errorLabelText: errorMsg, errorLableClassName: tempErrorClassName })
        return;
      }
    }
    
    // If all good then save
    this.saveParameters();
    
  }
  
  
  isValidConvFeeType()
  {
    if(!this.isConvFeeChecked()){
      return true;
    }
    return this.state.selectedConvFeeType ? true : false ;
  }
  
  
  isConvFeeChecked()
  {
    return this.state.convFee;
  }

  isFeeChecked()
  {
    return this.state.fee;
  }
  
  isValidFeeType(){
    if(!this.isFeeChecked()){
      return true;
    }
    return this.state.selectedFeeType ? true : false;
  }
  
  isValidConvFeeAmount(value)
  {
    var temp = Number(value)
    if(!this.isConvFeeChecked()){
      return true;
    }
    return this.isNumber(temp) &&  temp > 0 && temp <= 99999999
  }


  isValidSaleCeilingAmount(value)
  {
     if (this.state.selectedTransType != TransactionType.SALE)
     {
        return true;
     }
     
    var temp = Number(this.formatSaleCeilingAmount(value));
    return this.isNumber(temp) &&  temp > 0 && temp <= DEFAULT_SALE_CEILING_AMOUNT
  }

  isValidSECcode()
  {
    if (this.state.selectedTransType != TransactionType.SALE) {
      return true;
    }
    return !!this.state.SelectedSecCode?.trim()
  }

  //This function is used for removing any formatting with ceiling amount
  formatSaleCeilingAmount(value)
  {
    var t = value.replace(/\,/g,''); 
    return t;
  }
  
  

  saveParameters()
  {
    let encodedStr = ParamParseLib.getEncodedParameterString();
    let result = {};
    
    ///TODO: *NOTE* double encoding due to urlsearchparams not being encoded enough for transmission and storage
    encodedStr = window.btoa(encodedStr);
    
    let uri = process.env.API_URL + "/v1/merchant/create_payment_link";
    let requestSettings = {
      method: "post",
      body: JSON.stringify({link_params: encodedStr}),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "PayTrace-Product": "paymentlink"
      }
    };

    fetch(uri, requestSettings)
    .then(function(response){
      return response.json();
    })
    .then(function(response){
      let temp = "";
      let tempClassName = "";
      
      if (response["success"]){ 
       temp = "Your selections have been saved successfully."; 
       tempClassName = "alert alert-success"
      }
      else {
        temp = "Error while saving your selections. Please try again later." ;
        tempClassName = "alert alert-danger"
      } 
      this.setState({errorLabelText: temp, errorLableClassName: tempClassName })
  
    }.bind(this))
    .catch(function(error)
    {
      this.setState({errorLabelText: "NetWork Error while saving preferences. Please try again later." + error, errorLableClassName: "alert alert-danger" }) ;
    }.bind(this));
  }


  getCustomerLoginUI(merchantInfo)
  {
    if (!this.state.displayCustomerLoginUI)
    { // enables us to hide the customer login UI until we are ready to display it, or keep it hidden from certain merchants 
      return null;
    }

    let customerLoginLabelText = "Allow customers to save their payment information & create recurring payments. ";
    customerLoginLabelText = customerLoginLabelText + "All customer billing info will be stored in the customer profile database.";

    let enableButton = "";

    if (merchantInfo && !merchantInfo.isBasic) {
      enableButton = <RadioButton
        value={CustomerLogin.ENABLE}
        id="pref_CustomerLogin"
        name="pref_CustomerLogin"
        tooltipText="Retuning Customer login - Enable"
        labelText="Enable"
        style="checkbox"
        {...this.state.selectedCustomerLogin == CustomerLogin.ENABLE && {selected: true}}
        onChange={this.handleCustomerLoginEnableChange}/>
    }

    return <div style={{textAlign: 'left'}}>
      <h6>Returning Customer Login</h6>
      <div className="labelDesc">{customerLoginLabelText} </div>
      <br/>

      {enableButton}

      <RadioButton
        value={CustomerLogin.DISABLE}
        id="pref_CustomerLogin"
        name="pref_CustomerLogin"
        tooltipText="Retuning Customer login - Disable"
        labelText="Disable"
        style="checkbox"
        {...this.state.selectedCustomerLogin == CustomerLogin.DISABLE && {selected: true}}
        onChange={this.handleCustomerLoginDisableChange}/>

      </div>
  }


  getCustomTitleUI()
  {
    let cCustomTitleLabelText = "Please provide a title up to 25 characters for your custom payments such as Donations, Tax, Utility, or other payments where the customer specifies the amount. Currently, this title is Payment.";

    let customTitle = <div> 
      <div style={{ clear: 'both', paddingBottom: '30px' }} />
      <div style={{textAlign: 'left' }}>
        <h6>Custom Title</h6>
        <div className="labelDesc">{cCustomTitleLabelText}</div>
        <br/>
        <FormInput
          inputType="text"
          id="pref_CustomTitle"
          name="pref_CustomTitle"
          value={this.state.customTitle}
          placeholder="Payment"
          maxLength="25"
          width="30%"
          tooltip="Please provide a title up to 25 characters for your custom payments such as Donations, Tax, Utility, or other payments where the customer specifies the amount. Currently, this title is Payment."
          onChange={this.handleCustomTitleChange}
          />
      </div>
    </div>
    return customTitle
  }

  showACHUnavailableToolTip(label){
    let msg = "ACH only allows WEB or CCD for Payment Link SEC Codes."
    return (
      <div>
          <div style={{ clear: 'both', paddingBottom: '30px' }} />
          <div style={{textAlign: 'left' }}>
              <h6>{label}</h6>
              <div style={{color: "red"}} className="labelDesc">{msg} </div>
          </div>
      </div>
    ) 
  }

  getSecCodeUI(){
    if (!this.state.hasTraceACH){
      return null
    }

    if (this.state.selectedTransType != TransactionType.SALE)
    {
        return null;
    }

    let label = "ACH Settings"
    let secCodeLabelText = "Select default SEC code for your payments."
    const available_std_entry_classes = this.state.available_std_entry_classes

    // Check if merchant has any available sec code for payment link
    if (available_std_entry_classes.length < 1){
      return this.showACHUnavailableToolTip(label)
    }

    const secCodeOptions = available_std_entry_classes.reduce((obj, code) => {
      obj[code] = code;
      return obj;
    }, {'':''});
    
    return (
      <div> 
        <div style={{ clear: 'both', paddingBottom: '30px' }} />
        <div style={{textAlign: 'left' }}>
          <h6>{label}</h6>
          <div className="labelDesc">{secCodeLabelText} </div>
          <br/>
          <div className='form-input'>
            <DropDown 
              style={{width: "300px", height: "40px", paddingLeft: '15px'}}
              options={secCodeOptions} 
              onChange={this.handleSecCodeChange}
              value={this.state.SelectedSecCode} 
            />
          </div>
        </div>
      </div>
    )


  }


  getSaleCeilingAmountUI()
  {
    
    if (this.state.selectedTransType != TransactionType.SALE)
    {
      return null;
    }
    
    let cSaleCeilingAmountLabelText = "Please set the maximum sale amount that the customer will be allowed to process. The default maximum value is $99,999,999.99";
    
    let ceilingAmount = <div> 
      <div style={{textAlign: 'left' }}>
        <h6>Sale Ceiling Amount</h6>
        <div className="labelDesc">{cSaleCeilingAmountLabelText}</div>
        <br/>
        <FormInput
          inputType="text"
          icon = "$"
          id="pref_SaleCeilingAmount"
          name="pref_SaleCeilingAmount"
          value={this.state.saleCeilingAmount}
          placeholder="99,999,999.99"
          maxLength="13"
          width="30%"
          tooltip="Please set the maximum sale amount that the customer will be allowed to process. The default maximum value is $99,999,999.99."
          onChange={this.handleSaleCeilingAmountChange}
          />
      </div>
    </div>
    return ceilingAmount
  }


  getInvoiceUI()
  {
    let invoiceLabelText = "Choose how you would like the invoice field displayed for your customers.";
    
    return <div> 
          <div style={{ clear: 'both', paddingBottom: '30px' }} />
          <div style={{textAlign: 'left' }}>
              <h6>Invoice Field</h6>
              <div className="labelDesc">{invoiceLabelText} </div>
              <br/>
              <RadioButton
                value={DisplayInvoice.SHOW} 
                id="pref_DisplayInvoice"
                name="pref_DisplayInvoice"
                labelText={DisplayInvoice.SHOW}
                style="checkbox"
                {...this.state.SelectedDisplayInvoice == DisplayInvoice.SHOW && {selected:true}}
                onChange={this.handleDisplayInvoiceChange}/>
                
              <RadioButton
                value={DisplayInvoice.SHOWANDREQUIRE} 
                id="pref_DisplayInvoice"
                name="pref_DisplayInvoice"
                labelText={DisplayInvoice.SHOWANDREQUIRE}
                style="checkbox"
                {...this.state.SelectedDisplayInvoice == DisplayInvoice.SHOWANDREQUIRE && {selected:true}}
                onChange={this.handleDisplayInvoiceChange}/>
                  
              <RadioButton
                value={DisplayInvoice.HIDE} 
                id="pref_DisplayInvoice"
                name="pref_DisplayInvoice"
                labelText={DisplayInvoice.HIDE}
                style="checkbox"
                {...this.state.SelectedDisplayInvoice == DisplayInvoice.HIDE && {selected:true}}
                onChange={this.handleDisplayInvoiceChange}/>
          </div>
        </div>
  }


  getFeeUI(surchargeInfo)
  {
    if (!this.state.displayConvFeeUI)
    { // enables us to hide the customer login UI until we are ready to display it, or keep it hidden from certain merchants 
      return null;
    }
    // For toggling convenience fees fields UI

    
    let cFeeLabelText = "Collect a fee along with a payment from your customers.";
    let cFeeLabelText2 = "There are many card rules associated with different fees. Please consult your Merchant Service Provider to learn and understand the current rules and how you may be able to utilize this feature.";
    let cFeeTypeLabelText = "This type will quantify the amount provided below as a fixed amount or a percentage of the combined product amounts.";
    let cFeeAmountLabelText = "8 characters max. This amount will be treated based on your previous selection type.";
    let cFeeFieldTitleLabelText = "50 characters max. The default title is Fee.";
    // Surcharge label data
    let cFeeSurLabelText = "Surcharge fees for PaymentLink will be based on the surcharge feature that has been setup by your Merchant Service Provider.";

    let convFeesFieldsUI = "";
    let feeFieldsUI = "";
    let convFeesFieldsVisible = this.state.convFee;
    let feesFieldsVisible = (this.state.fee && surchargeInfo && surchargeInfo.is_surcharge_monthly_product);

    if(convFeesFieldsVisible)
    {    
      //convFeesFieldsUI = <ConvFeesFields/> //would like to do it this way and will be working on it when time permits
      convFeesFieldsUI = <div>
        <div style={{textAlign: 'left' }}>
          <h6>Fee Type</h6>
          <div className="labelDesc">{cFeeTypeLabelText}</div>
          <br/>
          <RadioButton
            value={ConvFeeType.FIXED} 
            id="pref_ConvFeeType"
            name="pref_ConvFeeType"
            tooltipText="Fee charged in Fixed Amount"
            labelText= {LabelConvFeeType.FIXED}
            style="checkbox"
            {...this.state.selectedConvFeeType == ConvFeeType.FIXED && {selected:true}}
            onChange={this.handleConvFeeTypeFixedChange}/>
          <RadioButton
              value={ConvFeeType.PERCENTAGE} 
              id="pref_ConvFeeType"
              name="pref_ConvFeeType"
              tooltipText="Fee charged in Percentage"
              labelText={LabelConvFeeType.PERCENTAGE} 
              style="checkbox"
              {...this.state.selectedConvFeeType == ConvFeeType.PERCENTAGE && {selected:true}}
              onChange={this.handleConvFeeTypePercentageChange}/>
        </div>

        <div style={{ clear: 'both', paddingBottom: '30px' }} />
        
        <div style={{textAlign: 'left' }}>
          <h6>Fee Amount</h6>
          <div className="labelDesc">{cFeeAmountLabelText}</div>
          <br/>
          <FormInput
            isValid={this.state.isConvFeeAmountValid}
            inputType="Text"
            id="pref_ConvFeeAmount"
            name="pref_ConvFeeAmount"
            value={this.state.convenienceFeeAmount}
            maxLength="8"
            width="20%"
            tooltip="Fee Amount"
            onChange={this.handleConvFeeAmountChange}> 
          </FormInput> 
        </div>
        
        <div style={{ clear: 'both', paddingBottom: '30px' }} />
        
        <div style={{textAlign: 'left' }}>
          <h6>Fee Field Title - optional</h6>
          <div className="labelDesc">{cFeeFieldTitleLabelText}</div>
          <br/>
          <FormInput
              inputType="text"
              id="pref_ConvFeeFieldTitle"
              name="pref_ConvFeeFieldTitle"
              value={this.state.convenienceFeeTitle}
              maxLength="50"
              width="30%"
              placeholder="Fee"
              onChange={this.handleConvFeeFieldTitleChange}
            >
            </FormInput>
        </div>
        
        <div style={{ clear: 'both', paddingBottom: '30px' }} />
      
      </div>
    }

    if(feesFieldsVisible)
    {
      feeFieldsUI = (
        <div>
          <div style={{ clear: 'both', paddingBottom: '30px' }} />
          <div style={{textAlign:'left'}}>
            <RadioButton 
              value={FeeType.CONVENIENCE}
              id="pref_DisplayFee"
              name="pref_DisplayFee"
              tooltipText="Add a Convenience Fee"
              labelText={LabelFeeType.CONVENIENCE}
              style="checkbox"
              {...this.state.selectedFeeType == FeeType.CONVENIENCE && {selected:true}}
              onChange={this.handleFeeChange}
            />
            {surchargeInfo && surchargeInfo.is_surcharge_monthly_product && (
              <RadioButton
                value={FeeType.SURCHARGE}
                id="pref_DisplayFee"
                name="pref_DisplayFee"
                tooltipText="Add a Surcharge Fee"
                labelText={LabelFeeType.SURCHARGE}
                style="checkbox"
                {...this.state.selectedFeeType == FeeType.SURCHARGE && {selected:true}}
                onChange={this.handleFeeChange}
              />
            )}
          </div>
        </div>
      )
    }
   

    return <div>
        <div style={{ clear: 'both', paddingBottom: '30px' }} />
        <div style={{textAlign: 'left' }}>
          <h6>Fees</h6>
          {surchargeInfo && surchargeInfo.is_surcharge_monthly_product ? (
            <div className='labelDesc'>
              {cFeeLabelText} <br /> {cFeeLabelText2} <br /> {cFeeSurLabelText}
              {this.state.surConvFeeChange && (
                <span>{this.state.surConvFeeChange}</span>
              )}
            </div>
          ) : (
            <div className='labelDesc'>
              {cFeeLabelText} <br /> {cFeeLabelText2}
            </div>  
          )}
          <br/>
          <FormCheckbox
            value="Y" 
            name="pref_Add_Fee"
            tooltipText="Add a Fee"
            labelText="Add a Fee"
            checked={surchargeInfo && surchargeInfo.is_surcharge_monthly_product ? this.state.fee : this.state.convFee}
            onChange={surchargeInfo && surchargeInfo.is_surcharge_monthly_product ? this.handleCheckFee : this.handleCheckConvFee}
            />
        </div>
        
        {feeFieldsUI}
        <div style={{ clear: 'both', paddingBottom: '30px' }} />
        {convFeesFieldsUI}
      </div>

  }


  getTransactionTypeUI()
  {
    let transTypeLabelText = "Sale & Authorization types will obtain a real time authorization for the transaction amount. Funds won't be settled until you capture it. ";
    transTypeLabelText = transTypeLabelText + "Approved sales automatically settle each day at the time specified for this account. ";
    // transTypeLabelText = transTypeLabelText + "Store & Forward transactions will not authorize or ";
    // transTypeLabelText = transTypeLabelText + "settle until they are processed on the Store & Forward page under the Virtual Terminal Menu.";

    let saleType = <RadioButton
                value={TransactionType.SALE} 
                name="pref_transactionType"
                tooltipText="Transaction Type - Sale"
                labelText="Sale/Purchase"
                style="checkbox"
                {...this.state.selectedTransType == TransactionType.SALE && {selected:true}}
                onChange={this.handleChangeTransactionType}
              />
  
    //only show auth and strfwd if it is enabled. (ie, hidden for early phases of project)
    let authType = ""
    let strfwdType = ""
    authType = <RadioButton
              value={TransactionType.AUTH} 
              name="pref_transactionType"
              tooltipText="Transaction Type - Authorization"
              labelText="Authorization"
              style="checkbox"
              {...this.state.selectedTransType == TransactionType.AUTH && {selected:true}}
              onChange={this.handleChangeTransactionType}  
            />
    if (this.state.showAuthAndSTRFWD)
    {
      strfwdType = <RadioButton
                value={TransactionType.STRFWD} 
                name="pref_transactionType"
                tooltipText="Transaction Type - Store and Forward Sale"
                labelText="Store & Forward"
                style="checkbox"
                {...this.state.selectedTransType == TransactionType.STRFWD && {selected:true}}
                onChange={this.handleChangeTransactionType}
              />
    }


    return  <div style={{textAlign: 'left' }}>
              <h6>Transaction Type</h6>
              <div className="labelDesc">{transTypeLabelText} </div>
              <br/>

              { saleType }
              { authType }
              { strfwdType }
                                
            </div>
  }


  render() {

    // Set the label info 
    
    let termsLinkLabelText = "Link to your terms & conditions for your customers.";

    return (

      <UserContext.Consumer>
      { 
        ({user, merchantInfo, surchargeInfo}) => {
          if (merchantInfo)
          { //checking that proper merchant info exists (ie, authenticated properly) before continuing
            this.getLinkParams(merchantInfo)

          }

         return <div className="settings-pref-designer" style={{backgroundColor: '#FFF'}}>

          <div style={{ color: '#3499cc', fontWeight: 'bold', padding: '10px', height: '40px', backgroundColor: '#f5f5f5', textAlign: 'left' }}>

          </div>

          <div className="designer-controls">
            
            <button className='create-save-button btn pt-primary' onClick={this.onSaveClick}>
              <Icon className="fas fa-check-circle" fgcolor="#FFF" bgcolor="#F98E38"/>Save
            </button>
            
            <div style={{ clear: 'both' }} />
            <div className={this.state.errorLableClassName}>{this.state.errorLabelText}</div>

            <div style={{ clear: 'both', paddingBottom: '40px' }} />

            { this.getTransactionTypeUI() }
            
            <div style={{ clear: 'both', paddingBottom: '30px' }} />
            
            <div style={{textAlign: 'left' }}>
              <h6>Terms Link</h6>
              <div className="labelDesc">{termsLinkLabelText}</div>
              <br/>
              <FormInput
                id="pref_TermsLink"
                inputType="url"
                name="pref_TermsLink"
                value={this.state.termsLink}
                placeholder="http://example.com"
                maxLength="255"
                pattern="https?://.+" 
                tooltip="Link to your terms and conditions. The link must include http:// prefix."
                onChange={this.handleTermsLinkChange}
                required="required"/>
            </div>
            
            <div style={{ clear: 'both', paddingBottom: '30px' }} />

            { this.getCustomerLoginUI(merchantInfo) }

            { this.getCustomTitleUI() }

            { this.getSecCodeUI()}
            
            { this.getInvoiceUI()}

            { this.getFeeUI(surchargeInfo) }
            
            { this.getSaleCeilingAmountUI() }

            <div style={{ clear: 'both', paddingBottom: '30px' }} />
          
          </div>

        </div>

      }
    }
    </UserContext.Consumer>

    );
  }
}

export default SettingsPrefDesigner;
