

// Promises are async so creating an object that 
// accepts a callback to be called. For example
// can be called in components did mount and accepting
// a callback. Not implemented but we can also 
// set up an abort controller to abort on component unmount event.
// Example this.abortController = new AbortController();
// Not worrying about it now though.

export const MIRetriever = {
  getMerchantInfo: function(callback){
    const uri = process.env.API_URL + "/v1/merchant/info";      
    console.debug("process.env.NODE_ENV = ", process.env.NODE_ENV);
    console.debug("process.env.API_URL = ", process.env.API_URL);
  
    if ( process.env.NODE_ENV == 'local') {     
      console.debug("Environment set to dev. setting placeholder merchant info");
      callback({
        merchantInfo: {
          merchant_id: '3', mid: '9999', website: 'www.amazingTestMerchant.com', dba: 'Amazing Test Merchant', email: 'info@amazingTestMerchant.com', isBasic: false,
          "ach": true,  
          "ach_processor_code": "VCI",
          "ach_std_entry_classes": [
            "TEL",
            "PPD",
            "WEB"
          ],
          "ach_processor_status": 1
          },
      });
      return;
    }
    else if (process.env.NODE_ENV === undefined) {
      console.debug("Environment not set. setting empty merchant info");
      callback({
        merchantInfo: {
          merchant_id: null, 
          mid: null, 
          website: null, 
          dba: null, 
          email: null, 
          isBasic: null, 
          ach: null, 
          ach_processor_code: null, 
          ach_std_entry_classes: null, 
          ach_processor_status: null
        },
      });
      return;

    }

    let requestSettings = {
      credentials: "include",
      headers: {
        "PayTrace-Product": "paymentlink"
      }
    };

    console.debug("fetching merchant info from", uri);
    fetch(uri, requestSettings)
      .then(function(response){
        return response.json();
      })
      .then(function(myJson){
        if (myJson["success"]) 
        {
         callback({
            merchantInfo: {
              merchant_id: myJson["merchant_id"], 
              mid: myJson["mid"], 
              website: myJson["website"], 
              dba: myJson["dba"], 
              email: myJson["email"], 
              isBasic: myJson["isBasic"],
              ach: myJson["ach"], 
              ach_processor_code: myJson["ach_processor_code"], 
              ach_std_entry_classes: myJson["ach_std_entry_classes"], 
              ach_processor_status: myJson["ach_processor_status"]
            },
          });
        } else {
          callback({
            merchantInfo: {
              merchant_id: null, 
              mid: null, 
              website: null, 
              dba: null, 
              email: null, 
              isBasic: null, 
              ach: null, 
              ach_processor_code: null, 
              ach_std_entry_classes: null, 
              ach_processor_status: null
            },
          });
        }
      })
      .catch(function(error){
        console.error("Error trying to retrieve merchant info", error);
        callback({
          merchantInfo: {
            merchant_id: null, 
            mid: null, 
            website: null, 
            dba: null, 
            email: null, 
            isBasic: null, 
            ach: null, 
            ach_processor_code: null, 
            ach_std_entry_classes: null, 
            ach_processor_status: null
          },
        });
      });
  },


  getSurchargeInfo: function(callback){
    const uri = process.env.API_URL + "/v1/merchant/merchant_sur_info";  

    if ( process.env.NODE_ENV == 'local') {     
      console.debug("Environment set to dev. setting placeholder merchant surcharge info");
      callback({
        surchargeInfo: {merchant_id: null, is_surcharge_monthly_product: false},
      });
      return;
    }
    else if (process.env.NODE_ENV === undefined) {
      console.debug("Error: Environment not set. Please set the NODE_ENV environment variable");
      callback({
        error: "Environment not set. Please set the NODE_ENV environment variable",
      });
      return;
    }

    fetch(uri, {
      method: 'POST',
      credentials: 'include',
      headers: {
        "PayTrace-Product": "paymentlink"
      }
    })
      .then(function(response){
        return response.json();
      })
      .then(function(myJson){
        if (myJson["merchant_id"]) 
        {
         callback({
            surchargeInfo: {merchant_id: myJson["merchant_id"], is_surcharge_monthly_product: myJson["is_surcharge_monthly_product"]},
          });
        } else {
          callback({
            surchargeInfo: {merchant_id: null, is_surcharge_monthly_product: null},
          });
        }
      })
      .catch(function(error){
        console.error("Error trying to retrieve merchant surcharge info", error);
        callback({
          surchargeInfo: {merchant_id: null, is_surcharge_monthly_product: null},
        });
      });
  }
}



